<template>
    <div>
        <el-row id="tabBarNav" :class="pagesetupHeight?'setupHei':'unSetupHei'">
            <el-col>
                <el-form :inline='true'>
                    <!-- <el-form-item label="省:">
                        <el-select 
                        @change="selectRowChange1" 
                        v-model="filters.selecSheng" 
                        ref ='provinceRef'
                        placeholder="请选择" 
                        v-loading='provinceLoading' 
                        :clearable="userInfoByToken.ProvinceID == null ? true : false ">
                            <template v-for="item in shengArr">
                                <el-option
                                    :key="item.ID"
                                    :label="item.Name"
                                    :value="item.ID"
                                    :disabled='provinceIsDisabled'>
                                </el-option>
                            </template>
                        </el-select>
                    </el-form-item> -->
                    <!-- <el-form-item label="市:">
                        <el-select 
                        @change="selectRowChange2" 
                        v-model="filters.selecShi" 
                        placeholder="请选择" 
                        ref ='cityRef'
                        v-loading='cityLoading' 
                        :clearable="userInfoByToken.CityID == null ? true : false">
                            <template v-for="item in shiArr">
                                <el-option                            
                                    :key="item.ID"
                                    :label="item.Name"
                                    :value="item.ID"
                                    :disabled='cityIsDisabled'>
                                </el-option>
                            </template>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="区/县:">
                        <el-select 
                        v-model="filters.selecXian" 
                        placeholder="请选择" 
                        ref ='regionRef'
                        v-loading='areaLoading' 
                        :clearable="userInfoByToken.RegionID == null ? true : false">
                            <template v-for="item in xianArr">
                                <el-option                            
                                    :key="item.ID"
                                    :label="item.Name"
                                    :value="item.ID"
                                    :disabled='areaIsDisabled'>
                                </el-option>
                            </template>
                        </el-select>
                    </el-form-item> -->
                    <el-form-item label="单位名称:">
                        <el-input v-model='filters.name' placeholder="输入单位名称" clearable @clear='clearContent'></el-input>
                    </el-form-item>
                    <el-form-item label="证书数量:">
                        <el-select v-model="filters.certificatesNum" placeholder="请选择证书数量">
                            <el-option
                                v-for="item in options"
                                :key="item.value"
                                :label="item.name"
                                :value="item.value"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <div style="display: flex;">
                            <toolbar :buttonList="buttonList" @callFunction="callFunction"></toolbar>
                            <!-- <el-button size="" @click="exportTemplate" style="margin-left: 10px;" type="warning">导出模板</el-button> -->
                        </div>
                    </el-form-item>
                </el-form>             
            </el-col>
        </el-row>
        <!--列表-->
        <el-table
            :data="listData"
            highlight-current-row
            v-loading="listLoading"
            border=""
            :cell-style="rowClass"
            @current-change="selectCurrentRow"
            :style="{marginTop:needHeight}"
            :maxHeight="tableHeight"
        >
            <!-- <el-table-column prop="Province" align="center" label="省" width="80" show-overflow-tooltip></el-table-column> -->
            <el-table-column prop="City" align="center" label="市" width="80" show-overflow-tooltip></el-table-column>
            <el-table-column prop="Region" align="center" label="区" width="80" show-overflow-tooltip></el-table-column>
            <el-table-column prop="UnitName" align="center" label="单位名称" min-width="170" show-overflow-tooltip></el-table-column>
            <el-table-column prop="SocialCreditNumber" align="center" label="社会统一信用代码证" min-width="170" show-overflow-tooltip></el-table-column>
            <el-table-column prop="CertificateRegisterCount" align="center" label="证书数量" min-width="100">
                <template slot-scope='scope'>
                    <router-link :to="{path:'/SocialUnitDetail',query:{id:scope.row.ID}}" style='color:blue;'>{{scope.row.CertificateRegisterCount}}</router-link>
                </template>
            </el-table-column>
            <el-table-column prop="LiablePerson" align="center" :formatter="changeStrFun1" label="消防责任人" min-width="100"></el-table-column>
            <el-table-column prop="LiablePersonPhoneNumber" align="center" :formatter="changeStrFun2" label="消防责任人手机" min-width="130"></el-table-column>
            <el-table-column prop="Administrator" align="center" :formatter="changeStrFun3" label="消防安全管理人" min-width="130"></el-table-column>
            <el-table-column prop="AdministratorPhoneNumber" align="center" :formatter="changeStrFun4" label="消防安全管理人手机" min-width="160"></el-table-column>
            <el-table-column prop="ContactPerson" align="center" :formatter="changeStrFun5" label="联系人" width="100"></el-table-column>
            <el-table-column prop="ContactPersonPhoneNumber" align="center" :formatter="changeStrFun6" label="联系人手机" min-width="120"></el-table-column>
            <el-table-column prop="Tel" align="center" :formatter="changeStrFun7" label="固定电话" min-width="100"></el-table-column>
            <el-table-column prop="Address" align="center" :formatter="changeStrFun8" label="地址" min-width="120" show-overflow-tooltip></el-table-column>
            <el-table-column prop="NoticeTime" align="center" label="通知时间" :formatter="formatCreateTime" min-width="100"></el-table-column>
            <el-table-column prop="NoticeCount" align="center" label="通知次数" min-width="100"></el-table-column>
            <el-table-column
                fixed="right"
                label="应训人员"
                align="center"
                width="100"
            >
                <template slot-scope="scope">
                    <el-button @click="handleClick(scope.row)" type="text" size="small">查看</el-button>
                </template>
            </el-table-column>
        </el-table>
        <!--底部工具条-->
        <el-col :span="24" class="pageBar">
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="pages.pageIndex"
                :page-sizes="pages.pageArr"
                :page-size="pages.pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="pages.dataCount">
            </el-pagination>
        </el-col>
        <!-- 创建人群 -->
        <!-- <el-col :span="24" class="pageBar">
            <div class="groupClass">
                <el-form :inline='true' :model='addSocialGroupFrom' :rules='addSocialGroupRules' ref='addSocialGroupRef'>
                    <el-form-item label="查询出单位数量:">
                        {{pages.dataCount}}
                    </el-form-item>
                    <el-form-item label="">
                    </el-form-item>
                    <el-form-item label="">
                    </el-form-item>
                    <el-form-item label="">
                        <span style="color: #409EFF">
                            {{addSocialGroupFrom.viewName}}
                        </span>
                    </el-form-item>
                    <el-form-item label="人群名称:" prop="groupName">
                        <el-input v-model='addSocialGroupFrom.groupName' placeholder="输入人群名称" maxlength="10" clearable></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click.native="addSocialGroupSubmit" :loading="addSocialGroupLoading">创建人群</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </el-col> -->
        <!-- 导入 -->
        <el-dialog
        title="导入"
        :visible.sync="addFormVisible"
        v-model="addFormVisible"
        :close-on-click-modal="false"
        width='500px'
        >
            <el-upload
                class="upload-demo"
                :drag="true"
                :limit="1"
                :action="importSocialUnit1"
                :headers='headers'
                :on-error="submitFileError"
                :on-success="submitFileSuccess"
                :before-upload="beforeUpload"
                :on-exceed="submitFileMore"
                :show-file-list="false"
                :multiple="false">
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                <div class="el-upload__tip" slot="tip">只能上传文件，且不超过 2MB</div>
            </el-upload>
            <div slot="footer" class="dialog-footer">
                <el-button @click.native="addFormVisible = false">取消</el-button>
            </div>
        </el-dialog>
        <!-- 查看 -->
        <el-dialog
        title="查看"
        :visible.sync="unitPersonnelDialog"
        v-model="unitPersonnelDialog"
        :close-on-click-modal="false"
        width='60%'
        >
            <div class="perDialog" v-for="(item,index) in unitPersonnelData" :key="index">
                <div class="perDialogTitle">
                    <div>岗位（人群）：{{item.StationName}}</div>
                    <div class="RegionSty" v-if="item.TradeName">行业（场所）：{{item.TradeName}}</div>
                </div>
                <div class="perDialogBox">
                    <div class="ialogBoxList" v-for="(o,i) in item.SocialUnitStaffList" :key="i">
                        <div></div>
                        <div>姓名：{{o.Name}}</div>
                        <div>手机号：{{o.PhoneNumber}}</div>
                        <div></div>
                    </div>
                </div>
            </div>
            <div v-if="unitPersonnelData.length == 0" class="noPerData">暂无数据</div>
            <div slot="footer" class="dialog-footer">
                <el-button @click.native="unitPersonnelDialog = false">取消</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import Toolbar from "../../components/Toolbar"
import { getButtonList } from "../../promissionRouter";
import { getSocialUnitDataListPage,importSocialUnit,getImportSocialUnitTemplateUrl,getAreaDictionaryList,getUserByToken,addSocialGroup,getSocialUnitStaffList } from '@/api/api'
import util from "../../../util/date";
import Qs from 'qs';
export default {
    components: {Toolbar},
    data() {
        return {
            needHeight: 0,
            tableHeight:'500px',
            pagesetupHeight: true,
            filters: {
                selecSheng: 1919,
                selecShi: '',
                selecXian: '',
                name: '',
                certificatesNum: '',
            },
            options: [
                {value:'222',name:'请选择证书数量'},
                {value:0,name:'0'},
                {value:1,name:'1'},
                {value:2,name:'2人及以上'},
            ],
            shengArr: [],
            shiArr: [],
            xianArr: [],
            // 父级id
            parentSheng: '',
            parentShi: '',
            // 行政区域分页参数
            pagesOpt1:{
                pageIndex:1,
                pageSize:10,
                total:0,
            },
            pagesOpt2:{
                pageIndex:1,
                pageSize:10,
                total:0,
            },
            pagesOpt3:{
                pageIndex:1,
                pageSize:10,
                total:0,
            },
            listLoading: false,
            listData: [],
            buttonList: [],
            //关于分页的obj
            pages: {
                pageSize: 20,//默认每页条数
                pageArr: [20, 40, 100, 200],//默认每页条数选择
                pageIndex: 1, //默认进入页
                dataCount: 20, //默认总页数
            },
            importSocialUnit1: '',
            addFormVisible: false,
            provinceLoading:false,
            cityLoading:false,
            areaLoading:false,
            provinceIsDisabled:false,
            cityIsDisabled:false,
            areaIsDisabled:false,
            userInfoByToken:{},
            // 创建人群
            addSocialGroupLoading: false,
            addSocialGroupFrom: {
                viewName: '',
                groupName: '',
            },
            addSocialGroupRules: {
                groupName:[{required:true,message:'请输入人群名称',trigger:'blur'}],
            },
            // 查看
            unitPersonnelDialog: false,
            unitPersonnelLoading: false,
            unitPersonnelData: [],
            ProvinceID: 1919,
            CityID: '',
            RegionID: '',
            TownID: '',
            isLevel: '',
        }
    },
    methods: {
        // 清空input框，重新获取数据
        clearContent(){
            this.getListData();
        },
        callFunction(item) {
            this[item.Func].apply(this, item);
        },
        //   表格样式处理
        rowClass ({columnIndex}) {
            return 'padding:8px 0!important;'
        },
        getListData(e) {
            var _this = this
            var params = {
                provinceID: this.ProvinceID,
                cityID: this.CityID,
                regionID: this.RegionID,
                townID: this.TownID,
                isLevel: this.isLevel ? this.isLevel : "",
                pageIndex: this.pages.pageIndex,
                pageSize: this.pages.pageSize,
                dataType: 1,
            }
            if(this.filters.name){
                params.socialUnitName = this.filters.name
            }
            if(this.filters.selecSheng){
                params.provinceID = this.filters.selecSheng
            }
            if(this.filters.selecShi){
                params.cityID = this.filters.selecShi
            }
            if(this.filters.selecXian){
                params.regionID = this.filters.selecXian
            }
            if(this.filters.certificatesNum != '222'){
                params.certificateRegisterCount = this.filters.certificatesNum
            }
            this.listLoading = true;
            getSocialUnitDataListPage(params).then((res) => {
                var result = res.data
                if(result.Success){
                    _this.listLoading = false;
                    this.pages.dataCount = result.Response.DataCount
                    this.listData = result.Response.Data
                    if(e == 1) {
                        this.$confirm(`确定将【` + this.pages.dataCount + `】单位数量创建人群？`,"提示",{
                        }).then(() => {
                            this.addSocialGroupLoading = true
                            var params = {
                                name: this.addSocialGroupFrom.viewName,
                                customName: this.addSocialGroupFrom.groupName,
                                groupType: 1,
                            }
                            // if(this.filters.selecSheng) {
                                // params.conditionJson = JSON.stringify({provinceID: this.filters.selecSheng})
                            params.conditionJson = JSON.stringify({provinceID: 1919})
                            if(this.filters.selecShi) {
                                    params.conditionJson = JSON.stringify({provinceID: 1919,cityID: this.filters.selecShi})
                                    if(this.filters.selecXian) {
                                        params.conditionJson = JSON.stringify({provinceID: 1919, cityID: this.filters.selecShi, regionID: this.filters.selecXian})
                                    }
                                // }
                            }
                            // else {
                            //     params.conditionJson = null
                            // }
                            addSocialGroup(params).then(res => {
                                this.addSocialGroupLoading = false
                                var result = res.data
                                if(result.Success) {
                                    this.$message.success(result.Message)
                                } else {
                                    this.$message.error(result.Message)
                                }
                            })
                        })
                        .catch(() => {
                            
                        })
                    }
                }
            })
        },
        // 查询
        getTableDataFun(e) {
            this.pages.pageIndex = 1
            this.getListData(e);
            // var provinceText = this.$refs.provinceRef.selected.label
            // var provinceText = "广东省"
            // var cityText = this.$refs.cityRef.selected.label
            // var regionText = this.$refs.regionRef.selected.label
            // if(provinceText && provinceText != 'undefined') {
            //     this.addSocialGroupFrom.viewName = this.getTimerFun() + provinceText
            //     if(cityText && cityText != 'undefined') {
            //         this.addSocialGroupFrom.viewName = this.getTimerFun() + provinceText + cityText
            //         if(regionText && regionText != 'undefined') {
            //             this.addSocialGroupFrom.viewName = this.getTimerFun() + provinceText + cityText + regionText
            //         }
            //     }
            // } else {
            //     this.addSocialGroupFrom.viewName = ''
            // }
        },
        // 导入
        importSocialUnit() {
            this.addFormVisible = true;
        },
        // 导出模板
        exportTemplate() {
            getImportSocialUnitTemplateUrl().then((res) => {
                if(res.status == 200){
                    window.open(res.data)
                }else{
                    this.$message({
                        message:'模板导出失败！',
                        type:'error'
                    })
                }
            })
        },
        // 创建人群
        addSocialGroupSubmit() {
            this.$refs['addSocialGroupRef'].validate(valid => {
                if(valid){
                    var isAddGroun = 1
                    this.getEnterpriseRegisterCertificateListPage(isAddGroun)
                }
            })
        },
        // 省选中
        selectRowChange1(row) {
            if(!row){
                this.shiArr = []
                this.xianArr = []
                this.filters.selecShi = ''
                this.filters.selecXian = ''
            }else{
                this.parentSheng = row
                var params = {
                    parentId: this.parentSheng,
                    level:1
                }
                this.getPlace(params)
            }           
        },
        // 市选中
        selectRowChange2(row) {
            if(!row){
                this.xianArr = []
                this.filters.selecXian = ''
            }else{
                this.parentShi = row
                var params = {
                    parentId: this.parentShi,
                    level:2
                }
                this.getPlace(params)
            }          
        },
        // 获取行政区域
        getPlace(params){
            switch(params.level){
                case 0:
                    this.provinceLoading = true
                    break;
                case 1:
                    this.cityLoading = true
                    break;
                case 2:
                    this.areaLoading = true
                    break
            }
            getAreaDictionaryList(params).then(res => {
                if(res.data.Success){
                    switch(params.level){
                        case 0:
                            this.provinceLoading = false
                            this.shengArr = res.data.Response.reverse();
                            break;
                        case 1:
                            this.cityLoading = false
                            this.shiArr = res.data.Response.reverse();
                            break;
                        case 2:
                            this.areaLoading = false
                            this.xianArr = res.data.Response.reverse();
                            break
                    }
                }else{return}
            }).catch(() => {})
        },
        // 行政区域选项分页
        pages1Change(value) {
            this.pagesOpt1.pageIndex = value
            var params = {
                level: 0,
            }
            this.getCityPage(params);
        },
        pages1Change2(value) {
            this.pagesOpt2.pageIndex = value
            var params = {
                parentId: this.parentSheng,
            }
            this.getCityPage2(params);
        },
        pages1Change3(value) {
            this.pagesOpt3.pageIndex = value
            var params = {
                parentId: this.parentShi,
            }
            this.getCityPage3(params);
        },
        selectCurrentRow(val) {

        },
        submitFileError() {
            this.$message({
                message:'文件上传失败！',
                type:'error'
            })
        },
        submitFileSuccess(res) {
            if(res.Success){
                this.$message({
                    message:'文件上传成功！',
                    type:'success'
                })
                this.addFormVisible = false;
                this.getListData();
            }else{
                this.$message({
                    message:res.Message,
                    type:'error'
                })
            }
        },
        // 图片上传之前函数
        beforeUpload(file) {
            const isLt2M = file.size / 1024 / 1024 > 2;
            var type = file.name.substring(file.name.lastIndexOf(".")+1);
            if (type == 'jpg' || type == 'png') {
                this.$message.error('只能上传文件!');
                return false;
            }
            if (!isLt2M === false) {
                this.$message.error('上传文件大小不能超过 2MB!');
                return false;
            }
        },
        submitFileMore() {
            this.$message({
                message:'只能上传一个文件！',
                type:'warning'
            })
        },
        // 查看
        handleClick(val) {
            this.unitPersonnelDialog = true
            var params = {
                socialUnitId: val.Id
            }
            this.unitPersonnelLoading = true
            getSocialUnitStaffList(params).then((res) => {
                var result = res.data
                console.log("result",result)
                if(result.Success) {
                    this.unitPersonnelData = result.Response
                }
                this.unitPersonnelLoading = false
            })
        },
        getToken() {
            return localStorage.getItem("Token")
        },
        // 分页
        handleCurrentChange(val) {
            // if(this.filters.selecSheng != null || this.filters.selecShi != null || this.filters.selecXian != null || this.filters.name != '' || this.filters.certificatesNum != ''){
            //     this.pages.pageIndex = 1
            // }else{
                this.pages.pageIndex = val;
            // }           
            this.getListData();
        },
        handleSizeChange(val) {
            this.pages.pageSize = val          
            this.getListData();
        },
        //时间格式化
        formatCreateTime: function(row, column) {
            return !row.NoticeTime || row.NoticeTime == ""
            ? ""
            : util.formatDate.format(new Date(row.NoticeTime), "yyyy-MM-dd hh:mm");
        },
        // 得到用户省市区的信息
        getUserInfo(){
            // var token = localStorage.getItem("Token")
            // getUserByToken({token}).then(res => {
            //     if(res.Success){
                    var data = JSON.parse(localStorage.getItem("user")) 
                    this.userInfoByToken = data  
                    if(data.ProvinceID == null){
                        this.shengArr = []
                        this.filters.selecSheng = ''
                    }else{
                        var params = {
                            parentId:data.ProvinceID,
                            level:1
                        }
                        this.getPlace(params)
                        this.provinceIsDisabled = true
                    }  
                    if(data.CityID == null){
                        this.shiArr = []
                        this.filters.selecShi = ''
                    }else{
                        var params = {
                            parentId:data.CityID,
                            level:2
                        }
                        this.getPlace(params)
                        this.cityIsDisabled = true
                    }
                    if(data.RegionID == null){
                        this.xianArr = []
                        this.filters.selecXian = ''
                    }else{
                        this.areaIsDisabled = true
                    }                    
                    this.filters.selecSheng = data.ProvinceID
                    this.filters.selecShi = data.CityID
                    this.filters.selecXian = data.RegionID
                    this.getListData();
            //     }else{return}
            // }).catch(() =>{})
        },
        // 根据首页跳转修改查询条件
        updatedFilterData(data) {
            if(data.provinceID) {
                this.filters.selecSheng = data.provinceID
                var params = {
                    parentId:data.provinceID,
                    level: 1
                }
                this.getPlace(params)
                this.provinceIsDisabled = true
            }
            if(data.cityID) {
                this.filters.selecShi = data.cityID
                var params = {
                    parentId:data.cityID,
                    level: 2
                }
                this.getPlace(params)
                this.cityIsDisabled = true
            }
            if(data.regionID) {
                this.filters.selecXian = data.regionID
            }
            this.getListData();
        },
        changeStrFun1: function(val) {
            if(val.LiablePerson == "null") {
                return ""
            } else {
                return val.LiablePerson
            }
        },
        changeStrFun2: function(val) {
            if(val.LiablePersonPhoneNumber == "null") {
                return ""
            } else {
                return val.LiablePersonPhoneNumber
            }
        },
        changeStrFun3: function(val) {
            if(val.Administrator == "null") {
                return ""
            } else {
                return val.Administrator
            }
        },
        changeStrFun4: function(val) {
            if(val.AdministratorPhoneNumber == "null") {
                return ""
            } else {
                return val.AdministratorPhoneNumber
            }
        },
        changeStrFun5: function(val) {
            if(val.ContactPerson == "null") {
                return ""
            } else {
                return val.ContactPerson
            }
        },
        changeStrFun6: function(val) {
            if(val.ContactPersonPhoneNumber == "null") {
                return ""
            } else {
                return val.ContactPersonPhoneNumber
            }
        },
        changeStrFun7: function(val) {
            if(val.Tel == "null") {
                return ""
            } else {
                return val.Tel
            }
        },
        changeStrFun8: function(val) {
            if(val.Address == "null") {
                return ""
            } else {
                return val.Address
            }
        },
        getTimerFun() {
            var d = new Date();
            var curr_date = d.getDate();
            var curr_month = d.getMonth() + 1; 
            var curr_year = d.getFullYear();
            String(curr_month).length < 2 ? (curr_month = "0" + curr_month): curr_month;
            String(curr_date).length < 2 ? (curr_date = "0" + curr_date): curr_date;
            var yyyyMMdd = curr_year + "" + curr_month +""+ curr_date;
            return yyyyMMdd;
        }
    },
    computed:{
        headers() {
            return{
                "Authorization": 'Bearer ' + this.getToken() // 直接从本地获取token就行
            }
        },
    },
    created() {
        this.ProvinceID = this.$route.query.ProvinceID
        this.CityID = this.$route.query.CityID
        this.RegionID = this.$route.query.RegionID
        this.TownID = this.$route.query.TownID
        this.isLevel = this.$route.query.isLevel
        this.importSocialUnit1 = importSocialUnit
    },
    mounted() {
        // var params = {
        //     level: 0,
        // }
        var params = {
            parentId: 1919,
            level: 1,
        }
        // this.getPlace(params)
        // this.getListData();
        if(this.$route.query.userDataParams) {
            var userDataParams = JSON.parse(this.$route.query.userDataParams)
        } else {
            var userDataParams = ''
        }
        if(userDataParams) {
            this.updatedFilterData(userDataParams)
        } else {
            this.getUserInfo()
        }
        let routers = window.localStorage.router
        ? JSON.parse(window.localStorage.router)
        : [];
        this.buttonList = getButtonList(this.$route.path, routers);
    },
    updated() {
        var height = document.getElementById("tabBarNav").offsetHeight
        var tableHeight = document.getElementById('app').offsetHeight - 92 - 62 - height
        if(tableHeight > 200) {
            this.pagesetupHeight = true
            this.needHeight = height + 'px'
            this.tableHeight = tableHeight
        }else{
            this.pagesetupHeight = false
            this.needHeight = 0 + 'px'
            this.tableHeight = ''
        }
    }
}
</script>
<style>
    .el-upload-dragger{
        margin:auto;
    }
    .el-upload{
        display:block!important;
    }
    .el-upload__tip{
        margin-left:60px;
    }
    .groupClass {
        height: 45px;
    }
</style>
<style lang="stylus" scoped>
    .setupHei {
        position fixed;
        top 110px;
        z-index 100;
        background-color #fff;
    }
    .perDialog {
        border-bottom: 1px solid #999;
        margin-bottom: 25px;
    }
    .perDialogTitle {
        display: flex;
        font-weight: bolder;
    }
    .RegionSty {
        margin-left: 55px;
    }
    .perDialogBox {
        display: flex;
        flex-wrap: wrap;
    }
    .ialogBoxList {
        width: 190px;
        height: 70px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }
    .noPerData {
        text-align: center;
    }
</style>